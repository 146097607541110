import { find, findIndex } from "lodash-es"
import { skipHydrate } from "pinia"

import { StockById, StockData, StockSector } from "../../types"

export const useStocksStore = defineStore("stocks", () => {
  /// // State
  const stocks = ref<StockData[]>([])
  const sectors = ref<StockSector[]>([])
  const isSectorsLoading = ref<boolean>(false)
  const currentStock = ref<StockData | StockById>({} as StockData)

  /// // Methods
  const setStocks = (data: any[]) => (stocks.value = data)
  const setSectors = (data: any[]) => (sectors.value = data)
  const setIsSectorsLoading = (data: boolean) => (isSectorsLoading.value = data)
  const setCurrentStock = (data: StockData | StockById | undefined) => (currentStock.value = data!)

  /// // Getters
  const getStockById = (id: string) => find(stocks.value, { id })
  const getStockIndexById = (id: string) => findIndex(stocks.value, { id })
  const getSectorById = (id: string | string[]) => find(sectors.value, { id })

  /// // Reset
  const resetStore = () => (stocks.value = [])

  return {
    stocks,
    sectors,
    isSectorsLoading,
    currentStock,
    setStocks,
    setSectors,
    setIsSectorsLoading,
    setCurrentStock,
    resetStore,
    getStockById,
    getStockIndexById,
    getSectorById: skipHydrate(getSectorById),
  }
})
